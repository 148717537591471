import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { SolutionsBanner } from 'components/blocks/solutions-banner';
import { Content } from 'components/pages/static-page/content';

import { buildPageMetadata } from '../utils/data-builders';

const StaticPage = (props) => {
  const {
    data: {
      wpPage,
      wpPage: { acf },
    },
  } = props;

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header />
      <Content title={wpPage.title} content={wpPage.content} />
      <SolutionsBanner
        title={acf.sectionBannerTitle}
        description={acf.sectionBannerDescription}
        buttonText={acf.sectionBannerButtonText}
        buttonLink={acf.sectionBannerButtonLink}
      />
      <Footer theme="light" />
    </Layout>
  );
};

export default StaticPage;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      title
      content
      acf {
        sectionBannerTitle
        sectionBannerDescription
        sectionBannerButtonText
        sectionBannerButtonLink
      }
    }
  }
`;
