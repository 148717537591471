import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';
import { Title } from 'components/shared/title';

export const Wrapper = styled.section`
  position: relative;
  padding-top: 167px;
  padding-bottom: 108px;
  ${media.xs} {
    padding-bottom: 58px;
  }
`;

export const Inner = styled.div`
  max-width: 680px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledTitle = styled(Title)`
  font-size: 52px;
  font-weight: normal;
  line-height: 1.31;
  text-align: center;
  margin-bottom: 85px;
  ${media.sm} {
    font-size: 52px;
  }
  ${media.xs} {
    font-size: 44px;
    margin-bottom: 45px;
  }
`;

export const Description = styled.div`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.secondary};
  p {
    margin: 16px 0;
    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    margin: 64px 0 16px;
    font-size: 36px;
    font-weight: normal;
    line-height: 1.33;
    color: ${color.text.primary};

    ${media.sm} {
      font-size: 24px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 1.58;
    font-weight: normal;
    color: ${color.text.primary};

    ${media.sm} {
      font-size: 18px;
    }
  }

  blockquote {
    &,
    p {
      margin: 64px 0;
      background-image: linear-gradient(to right, #a27ffc, #5457bf);
      font-size: 36px;
      font-weight: 500;
      line-height: 1.33;
      text-align: center;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      margin: 0;
    }

    ${media.sm} {
      font-size: 24px;
    }

    cite {
      font-size: 20px;
      margin-top: 30px;
      display: block;
    }
  }

  a {
    color: ${color.primary};
  }

  figure {
    margin: 0;
  }

  img {
    max-width: 100%;
  }

  table {
    display: block;
    border-collapse: collapse;
    max-width: 100%;
    overflow: scroll;
  }

  td {
    border: 1px solid #eaeef2;
    padding: 3px 8px;
  }
`;
