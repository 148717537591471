import styled from 'styled-components';

import { media, color, fontSize } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const Wrapper = styled.section`
  margin-bottom: 150px;
  ${media.md} {
    margin-bottom: 100px;
  }
  ${media.sm} {
    margin-bottom: 50px;
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
  @media (min-width: 1378px) {
    max-width: 1318px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 47px 67px 57px 54px;
  border-radius: 8px;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  background-color: ${color.text.tertiary};
  ${media.md} {
    flex-direction: column;
    justify-content: center;
  }
  ${media.sm} {
    padding: 30px 45px 44px 45px;
  }
  ${media.xs} {
    padding: 30px 10px 44px 10px;
  }
`;

export const Img = styled.img`
  ${media.md} {
    margin-bottom: 20px;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  ${media.md} {
    flex-direction: column;
  }
`;

export const Text = styled.div`
  margin-left: 40px;
  ${media.lg} {
    max-width: 396px;
  }
  ${media.md} {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 100%;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 30px;
  }
`;

export const TitleStyled = styled(Title)`
  font-size: ${fontSize.lg};
  font-weight: normal;
  line-height: 1.33;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
  ${media.md} {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 18px;
    text-align: center;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 1.56;
  color: #838791;
  margin: 0;
  ${media.md} {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    padding: 0 31px;
  }
`;
