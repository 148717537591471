import { Link } from 'gatsby';
import React from 'react';

import { Button } from 'components/shared/button';

import LogoImgSvgUrl from './images/wegift-blue.url.svg';
import {
  Wrapper,
  Inner,
  ContentWrapper,
  Left,
  Img,
  TitleStyled,
  Text,
  Description,
} from './solutions-banner.styles';

export const SolutionsBanner = ({ title, description, buttonText, buttonLink }) => (
  <Wrapper>
    <Inner>
      <ContentWrapper>
        <Left>
          <Img
            src={LogoImgSvgUrl}
            width={80}
            height={80}
            alt="WeGift Logo"
            loading="lazy"
            aria-hidden
          />
          <Text>
            <TitleStyled as="h3">{title}</TitleStyled>
            {description && <Description>{description}</Description>}
          </Text>
        </Left>
        <Button component={Link} theme="fill" to={buttonLink}>
          {buttonText}
        </Button>
      </ContentWrapper>
    </Inner>
  </Wrapper>
);
